import styled, {css} from 'styled-components'

import {PageBackground} from '../../styles/_colors'

export const StyledBurger = styled.button`
  display: block;
  position: absolute;
  top: -13px;
  right: 0;
  z-index: 8;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  transition: all 0.3s ease-in;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;

  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }

  div {
    width: 100%;
    max-width: 30px;
    height: 2px;
    background: white;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      opacity: ${({open}) => (open ? '0' : '1')};
      transform: ${({open}) =>
        open ? 'rotate(90deg)' : ' rotate(90deg)'};
      margin-left: 15px;
      margin-bottom: 15px;
      width: 18px;
    }

    :nth-child(2) {
      position: absolute;
      width: 18px;
    }
  }
`

export const BurgerDiv = styled.div``
