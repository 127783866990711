import React from 'react'

import {Translate} from 'react-localize-redux'

import {
  DecreaseBagItemQuantity,
  DeleteBagItem,
  GetEmployeeBag,
  IncreaseBagItemQuantity
} from '../../../infra/requests/BagRequests'
import Currency from '../../../infra/utils/Currency'
import GetImage from '../Image'
import {
  BagProductContainer,
  BagProductWrapper,
  Counter,
  CounterIcon,
  CounterText,
  Price,
  PriceSpan,
  ProductCategory,
  ProductCount,
  ProductImage,
  ProductInfo,
  ProductInfoWrapper,
  ProductName,
  ProductNameLink,
  ProductSize,
  RemoveItem
} from './BagStyles'

const CartItem = ({user, bag, setBag, activeLanguage}) => {
  const languageId = user.languageId - 1

  async function RemoveProductFromBag(productId) {
    const {success} = await DeleteBagItem(productId)

    if (success) {
      const result = await GetEmployeeBag()

      if (result.success) {
        setBag(result.data)
      }
    }
  }

  async function DecreaseQuantity(bagItemId) {
    const {success} = await DecreaseBagItemQuantity(
      bagItemId,
      user.languageId
    )

    if (success) {
      const result = await GetEmployeeBag()

      if (result.success) {
        setBag(result.data)
      }
    }
  }

  async function IncreaseQuantity(bagItemId) {
    const {success} = await IncreaseBagItemQuantity(
      bagItemId,
      user.languageId
    )

    if (success) {
      const result = await GetEmployeeBag()

      if (result.success) {
        setBag(result.data)
      }
    }
  }

  return (
    <>
      {bag?.map((product) => (
        <BagProductContainer key={product?.bagItemId}>
          <BagProductWrapper>
            <RemoveItem
              href='#'
              onClick={() => RemoveProductFromBag(product?.bagItemId)}
            />
            <ProductImage
              src={GetImage(
                product?.product?.productImage?.find((x) => x.cover)
              )}
            />
            <ProductInfoWrapper>
              <ProductInfo>
                <ProductCategory
                  style={{
                    color: user.configuration?.layoutColors?.contentText
                  }}
                >
                  {
                    product?.product?.mainCategory?.categoryTranslation[
                      languageId
                    ]?.name
                  }
                </ProductCategory>
                <ProductName>
                  <ProductNameLink>
                    {
                      product?.product?.productTranslation[languageId]
                        ?.name
                    }
                  </ProductNameLink>
                </ProductName>
                <ProductSize>
                  {product?.colorTranslation[languageId]?.name}
                  {' / '}
                  {product?.fitting?.productMeasure?.size?.number}
                </ProductSize>
                <ProductSize />
              </ProductInfo>
              <ProductCount>
                <Counter>
                  {product?.units < 2 ? (
                    <CounterIcon
                      onClick={() =>
                        RemoveProductFromBag(product?.bagItemId)
                      }
                    >
                      <span>-</span>
                    </CounterIcon>
                  ) : (
                    <CounterIcon
                      onClick={() => DecreaseQuantity(product?.bagItemId)}
                    >
                      <span>-</span>
                    </CounterIcon>
                  )}

                  <CounterText>{product?.units}</CounterText>
                  <CounterIcon
                    onClick={() => IncreaseQuantity(product?.bagItemId)}
                  >
                    <span>+</span>
                  </CounterIcon>
                </Counter>
                <Price>
                  {product?.dotation === 0 ? (
                    <PriceSpan>
                      {Currency.format(product?.unitValue)}
                    </PriceSpan>
                  ) : (
                    <PriceSpan>
                      {product?.units}{' '}
                      {product?.units === 1 ? (
                        <Translate id='CART_CREDIT' />
                      ) : (
                        <Translate id='CART_CREDITS' />
                      )}
                    </PriceSpan>
                  )}
                </Price>
              </ProductCount>
            </ProductInfoWrapper>
          </BagProductWrapper>
        </BagProductContainer>
      ))}
    </>
  )
}

export default CartItem
