import React from 'react'

import {withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {userSave} from '../../../redux/data/user/UserActions'
import {
  LanguageSelec,
  LangCont,
  LangOption,
  BalloonTip,
  BalloonTipLeft,
  BalloonTipRight
} from './LanguageContainerStyles'

const LanguageContainer = ({
  user,
  dispatch,
  languages,
  activeLanguage,
  setActiveLanguage
}) => {
  const toggleLanguage = (e) => {
    setActiveLanguage(parseInt(e.target.value, 10))
    dispatch(userSave({...user, languageId: +e.target.value}))
  }

  return (
    <LangCont>
      <LanguageSelec
        defaultValue={activeLanguage?.code || 2}
        name='language'
        id='lang'
        tag='select'
        onChange={(e) => toggleLanguage(e)}
        style={{color: user.configuration?.layoutColors?.navMenuText}}
      >
        {languages.map((language, index) => (
          <LangOption
            key={index}
            value={language.code}
            style={{
              color: 'black'
            }}
          >
            {language.abrev}
          </LangOption>
        ))}
      </LanguageSelec>
      <BalloonTip>
        <BalloonTipLeft
          style={{
            backgroundColor: user.configuration?.layoutColors?.navMenuText
          }}
        />
        <BalloonTipRight
          style={{
            backgroundColor: user.configuration?.layoutColors?.navMenuText
          }}
        />
      </BalloonTip>
    </LangCont>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(LanguageContainer))
