export default {
  OOPS_PAGE_NOT_FOUND: 'Oeps... Pagina niet gevonden!',
  PAGE_NOT_FOUND_DESCRIPTION_1: 'Onze excuses voor het ongemak.',
  PAGE_NOT_FOUND_DESCRIPTION_2:
    'Blijkbaar is de pagina die u probeert te vinden gewist of heeft nooit bestaan.',
  BACK_TO_INITIAL_PAGE: 'Terug naar de beginpagina',
  PASSWORD_DOESNT_MATCH: 'Wachtwoord komt niet overeen',
  OUTFIT: 'ARTIKEL',
  CREDITS: 'AANSPRAAK',
  FITTING: 'GROOTTE BEOORDELING',
  MY_ORDERS: 'BESTELLINGEN',
  ADDRESSES: 'ADRES BOEK',
  SUPPORT: 'ONDERSTEUNING',
  ACCOUNT_SETTINGS: 'ACCOUNT INSTELLINGEN',
  MY_BAG: 'Mijn tas',
  OUTFIT_TITLE: 'Werkschoenen',
  OUTFIT_SUBTITLE:
    'Voeg toe aan je mand alle items die je nodig hebt om je uniforme compleet te maken.',
  CREDIT_TITLE: 'Aanspraak',
  CREDIT_SUBTITLE: 'Checken uw balans per item',
  ORDERS_TITLE: 'Bestellingen',
  ORDERS_SUBTITLE: 'bestellingen gevonden',
  SUPPORT_TITLE: 'FAQ & ondersteuning',
  SUPPORT_SUBTITLE: '',
  FITTING_TITLE: 'Grootte Beoordeling',
  FITTING_SUBTITLE: 'Checken de uniformen maatregelen',
  ADDRESSES_TITLE: 'Adres boek',
  ADDRESSES_SUBTITLE: 'Beheer uw adresseren',
  PERSONAL_DATA: 'Persoonlijke gegevens',
  LOGIN: 'Login',
  SIGNUP_NEWSLETTER: 'Ik wil me aanmelden voor de Skypro-nieuwsbrief',
  NO_DATA_TO_DISPLAY: 'Geen gegevens om weer te geven!',
  REVOKE_ACCESS:
    'Toegang intrekken tot mijn gegevens die zijn gegeven met toestemming van het SKYPRO-privacybeleid (deze handeling zal u uitloggen en het gebruik van het mySkypro-portaal voorkomen)',
  ORDER_ALL_OUTFIT: 'BESTELLING ALLE SET',
  SAVE: 'OPSLAAN',
  EDIT: 'BEWERK',
  ADD_NEW_ADDRESS: 'NIEUW ADRES TOEVOEGEN',
  CLOSE: 'SLUITEN',
  PRIMARY_ADDRESS: 'Primair adre',
  LOGOUT: 'UITLOGGEN',
  EMAIL_USERNAME: 'Email / Gebruikersnaam',
  CURRENT_PASSWORD: 'Huidig wachtwoord',
  NEW_PASSWORD: 'Nieuw paswoord',
  CONFIRM_PASSWORD: 'Bevestig nieuw wachtwoord',
  ADDRESS_NAME: 'Adres naam',
  NAME_FOR_DELIVERY: 'Naam voor levering',
  ADDRESS_LINE_1: 'Adres lijn 1',
  ADDRESS_LINE_2: 'Adres lijn 2',
  ADDRESS_LINE_3: 'Adres lijn 3',
  STREET_NUMBER: 'Straat nummer',
  ZIP_CODE: 'Postcode',
  CITY: 'Stad',
  REGION: 'Regio',
  COUNTRY: 'Land',
  PHONE_NUMBER: 'Telefoonnummer',
  VAT_NUMBER: 'BTW-nummer',
  ADD_NEW_ADDRESS_LOWER: 'Nieuw adres toevoegen',
  USED_CREDITS: 'CREDITS GEBRUIKT',
  ITEMS_IN_OUTFIT: 'Items in je Set',
  OTHER_ITEMS_FOR_YOU: 'Andere artikelen voor jou',
  OTHER_ITEMS_DESCRIPTION:
    'We hebben andere items gevonden die je uniform kunnen aanvullen.',
  WELCOME_LOGIN: 'Welkom bij',
  WELCOME_SUB:
    'Verhoog de efficiëntie en geef uw bemanning meer mogelijkheden',
  WELCOME_SUB_DEFAULT:
    'Een unieke mix van kennis en technologie die voor uw bedrijf de beste crewcare-oplossingen ter wereld creëert',
  SIGN_IN: 'Aanmelden',
  RECOVER: 'Herstellen',
  EMAIL_LOGIN: 'E-mail',
  PASSWORD_LOGIN: 'Wachtwoord',
  LOG_IN: 'Inloggen',
  REMEMBER_ME: 'Mijn gegevens bewaren',
  RECOVER_PASSWORD: 'Je wachtwoord vergeten?',
  ACCOUNT: 'account',
  ORDERS: 'orde',
  FITTING_SMALL: 'Grootte Beoordeling',
  HOW_CHANGE_PASSWORD: 'Hoe verander ik mijn wachtwoord?',
  HOW_CHANGE_PASSWORD_ANSWER:
    'Klik op uw naam in de rechterbovenhoek of <a href="/accountsettings">hier</a> om naar uw accountdefinities te gaan, waar u uw wachtwoord kunt wijzigen om in te loggen op uw account.',
  HOW_CHECK_CREDITS: 'Hoe controleer ik mijn tegoeden?',
  HOW_CHECK_CREDITS_ANSWER:
    'Je kunt je tegoeden bekijken op <a href="/credits">Credits</a>. Je vindt een lijst met alle items op je uniform en hun resterende en totale tegoeden, evenals de verlengingsdatum, indien van toepassing.',
  HOW_CHANGE_ADDRESSES:
    'Hoe wijzig/voeg ik adressen en contactpersonen toe?',
  HOW_CHANGE_ADDRESSES_ANSWER:
    'Ga naar <a href="/addresses">Adressen</a> om adressen en contacten te wijzigen/toe te voegen. Klik op "Bewerken" om de gegevens te wijzigen of op "Nieuw adres toevoegen" om een extra adres toe te voegen. Vergeet niet dat u uw contacten moet bijwerken voordat u een bestelling voltooit.',
  HOW_CHECK_UNIFORM: 'Hoe controleer ik mijn uniform?',
  HOW_CHECK_UNIFORM_ANSWER:
    'Je kunt je uniformitems bekijken op <a href="/outfit">Outfit</a>. Alleen de items waaruit uw uniform bestaat, worden weergegeven.',
  HOW_CHANGE_SIZE: 'Hoe wijzig ik de maat van mijn uniform?',
  HOW_CHANGE_SIZE_ANSWER:
    'Plan bij <a href="/measures">Maatregelen</a> een nieuwe aanpassessie door op "Opnieuw plannen" te klikken en een datum, tijd en plaats te kiezen.',
  HOW_SCHEDULE_FITTING: 'Hoe plan ik een fitting?',
  HOW_SCHEDULE_FITTING_ANSWER:
    'Ga naar <a href="/measures">Maatregelen</a>, klik op "Plannen" of "Herplannen" en kies een datum, tijd en plaats.',
  HOW_CHECK_STATUS: 'Hoe controleer ik de status van mijn bestelling?',
  HOW_CHECK_STATUS_ANSWER:
    'Ga naar <a href="/myorders">Mijn bestellingen</a>, zoek naar uw bestelnummer en klik op het pictogram "+" om meer details over uw bestelling te bekijken.',
  RECEIVED_INCOMPLETE: 'Ik heb een onvolledige bestelling ontvangen.',
  RECEIVED_INCOMPLETE_ANSWER:
    'Het kan zijn dat uw bestelling in pakketten is verzonden. De bestelling wordt afgerond zodra er voorraad is. Na de expeditie ontvangt u een e-mail met daarin de artikel(en) met het trackingnummer voor elke levering.',
  RECEIVED_INCORRECT: 'Ik heb onjuiste artikel(en) ontvangen.',
  RECEIVED_INCORRECT_ANSWER:
    'U heeft de door u geselecteerde artikelen ontvangen en ondertekend op het moment dat de montage werd uitgevoerd. Als er een verkeerd artikel is veroorzaakt door onze logistieke diensten, neem dan contact op met cs@wearskypro.com. \n\n Let op: uw bedrijf accepteert geen retourzendingen van artikelen met de door u geselecteerde maten.\n Let op: de verzending van artikelen met nieuwe maten om ruilredenen brengt verzendkosten met zich mee die u zelf moet betalen.',
  PAYMENT_METHODS: 'Betaalmethoden.',
  PAYMENT_METHODS_ANSWER:
    'Wij accepteren de volgende soorten betalingen:\n VISA\n ATM\n Paypal\n MasterCard\n American Express\n\n Wij garanderen dat uw betaling veilig kan worden gedaan in overeenstemming met de internationale veiligheidsnormen voor online betalingen. \n Uw creditcardnummer wordt ingevoegd op een beveiligde pagina, gehost door onze bank, en daarom extern aan onze site. Tijdens het betalingsproces wordt geen creditcardnummer geregistreerd of zichtbaar voor onze site. We hebben geen toegang tot uw betalingsgegevens.\n Deze website maakt gebruik van de Secure Sockets Layer (SSL)-technologie om de veiligst mogelijke winkelervaring te bieden. De SSL-technologie maakt de versleuteling (codering) van vertrouwelijke informatie tijdens uw online transacties mogelijk. Alle formulieren op onze site zijn beveiligd met dezelfde SSL-technologie, zodat uw persoonlijke gegevens veilig blijven.',
  REFUND_PERIOD: 'Terugbetalingsperiode.',
  REFUND_PERIOD_ANSWER:
    'Terugbetalingen gebeuren via dezelfde betaalmethode die gebruikt is om de bestelling te verwerken, binnen een periode van 5 tot 10 werkdagen, na ontvangst van de geretourneerde artikel(en).\n\n Als u betaalt met een cadeaubon of betaalkaart ( virtueel of fysiek), wordt de terugbetaling op dezelfde kaart bijgeschreven. Als u op het moment van de terugbetaling de kaart die voor de betaling is gebruikt niet meer bij u heeft, raden wij u aan contact op te nemen met onze klantenservice via cs@wearskypro.com.',
  INCIDENT_UNIFORM:
    'Tijdens mijn werk had ik een incident met mijn uniform. Kan ik het ruilen?',
  INCIDENT_UNIFORM_ANSWER:
    'Ga naar <a href="/orders">Bestellingen</a>, "uniformincidenten" en leg de situatie uit. Indien uw klacht wordt geaccepteerd, wordt er een melding verzonden waarin staat dat er een nieuw artikel beschikbaar is ter vervanging van het beschadigde artikel.\n Indien uw klacht niet wordt geaccepteerd, wordt een melding verzonden met de reden.',
  GENERAL: 'Algemeen',
  PAYMENTS: 'Betalingen',
  RETURNS_EXCHANGES: 'Retourneren en ruilen',
  HELPFUL: 'Behulpzaam',
  NOT_HELPFUL: 'Niet behulpzaam',
  EXTRA_HELP: 'Extra hulp nodig?',
  DETAILS_HEADER: 'Details',
  COMPOSITION: 'Samenstelling',
  COMPOSITION_ANSWER: '50% katoen, 47% polyamide, 3% elastaan',
  WEIGHT: 'Gewicht',
  WEIGHT_ANSWER: '335grs',
  WIDTH: 'Breedte',
  WIDTH_ANSWER: '160CM',
  TEXTILE: 'Textiel',
  TWILL: 'Keperstof',
  TECH_SUBTITLE:
    'Volledige bescherming en veiligheid voor uw intensieve werkleven.',
  ABSOLUTE_COMFORT: 'Absoluut comfort',
  ABSOLUTE_COMFORT_TEXT:
    'Skypro-inlegzolen maken gebruik van een speciaal microcellulair structuurmateriaal met verbazingwekkende eigenschappen. Ze verliezen geen demping, zijn ademend, zacht en veerkrachtig, absorberen grondstoten en beschermen zo de wervelkolom en gewrichten en bieden een hogere flexibiliteit voor absoluut comfort.',
  EASY_CLEAN: 'Gemakkelijk schoon te maken',
  EASY_CLEAN_TEXT:
    'Door de afstotende eigenschappen van het oppervlak van onze stoffen te verbeteren en gebruik te maken van zorgvuldig geselecteerde toptechnologie, kunnen vlekken gemakkelijk worden verwijderd. Met deze afwerking worden de droogeigenschappen van de stof verbeterd en garanderen we een veel duurzamer kledingstuk.',
  ECOLOGIC: 'Ecologisch',
  ECOLOGIC_TEXT:
    'Garandeer een minimale afval-, verpakkings- en ecologische voetafdruk in overeenstemming met de VN-aanbevelingen, waarbij prioriteit wordt gegeven aan recycling en hergebruikte verpakkingen en het gebruik van duurzame, hernieuwbare en ecologische grondstoffen wordt bevorderd.',
  RELATED_TITLE: 'Andere artikelen voor jou',
  COLORS_TITLE: 'Verkrijgbare kleuren',
  IMAGE: 'Afbeelding',
  TECHNOLOGY: 'Technologie',
  RELATED: 'Verwant',
  CHECKOUT: 'Uitchecken',
  CONFIRM_DETAILS: 'Bevestig hieronder uw gegevens',
  CONTACT: 'Contact',
  SEND_TO: 'Verzend naar',
  PAYMENT_METHOD: 'Betalingsmiddel',
  SECURE_ENCRYPTED: 'Alle transacties zijn veilig en gecodeerd',
  CHANGE: 'Wijziging',
  CREDIT_CARD: 'Kredietkaart',
  CREDIT_CARD_TEXT:
    'Wanneer u op Bestelling voltooien klikt, wordt u doorgestuurd naar de betaalpagina',
  ATM: 'Multibanco/ATM-referentie',
  ATM_TEXT: 'U ontvangt een e-mail met de betalingsgegevens',
  BILLING_DATA: 'Betaal informatie',
  INVOICE_TO: 'Factuur aan',
  COMPLETE_ORDER: 'Maak bestelling af',
  REFUND_POLICY: 'Terugbetalingsbeleid',
  PRIVACY_POLICY: 'Privacybeleid',
  TERMS_SERVICE: 'Servicevoorwaarden',
  VOUCHER_CODE: 'Voucher code',
  APPLY: 'Toepassen',
  SUBTOTAL: 'Subtotaal',
  TRANSPORTATION: 'Verzenden',
  TOTAL: 'Totaal',
  NO_ITEMS: 'U hebt niets in uw winkelwagen.',
  SCROLL_DOWN: 'Naar beneden scrollen',
  COLOR: 'KLEUR',
  STATUS: 'Staat',
  VALUE_TAG: 'Gesloten',
  STATUS_TAG: 'Vernieuwt op:',
  ORDER_BTN: 'Orde compleet uniforme',
  FUNCTION: 'Functie:',
  OUTFIT_ITEMS: 'Items in Set:',
  NEXT_FITTING: 'Volgend betamelijk:',
  RESCHEDULE: 'Opnieuw plannen',
  SCHEDULE: 'Gepland',
  ABOUT: 'Over Skypro',
  PROHEALTH: 'ProHealth-technologie',
  CUSTOMER: 'Klantendienst',
  FOLLOW: 'Volg Ons',
  NEWSLETTER: 'Nieuwsbrief',
  BLOG: 'Bloggen',
  COPYRIGHT: '©2023 SKYPRO. Alle rechten voorbehouden.',
  ORDER: 'Bestelling',
  DATE: 'Datum',
  DELIVERY: 'Levering',
  NO_ORDERS: 'Geen bestellingen gevonden',
  DELIVERY_ADDRESS: 'Levering Adres',
  PROBLEM_CONTACT: 'Probleem? Neem contact met ons op',
  ORDER_SUMMARY: 'Overzicht van de bestelling',
  ITEMS: 'Artikelen',
  POSTAGE_PACKING: 'Haven & Inpakken',
  ORDER_TOTAL: 'Totaal bestelling',
  DATE_SUBMISSION: 'Datum van onderwerping',
  ESTIMATED_DELIVERY: 'Geschatte levering',
  PAYMENT_STATE: 'Betaling status',
  GET_REFERENCE: 'Ga door naar betaling',
  QUANTITY: 'Kwantiteit',
  ADD_ADDRESS: 'Nieuw adres toevoegen',
  EDIT_ADDRESS: 'Verander adres',
  SUBMIT: 'Indienen',
  ADDRESS_BOOK: 'Adres boek',
  MANAGE_ADDRESS: 'Beheer uw adresseren',
  CITY_REQUIRED: 'Plaats is een verplicht veld',
  SCHEDULE_FITTING_TITLE: 'Plan het passen',
  SCHEDULE_FITTING_SUBTITLE: 'Kies waar u wilt laten meten',
  HELLO: 'Hallo',
  CREDITS_LEFT: 'TEGOEDEN OVER',
  OF: 'VAN',
  RENEWS_ON: 'VERNIEUWT OP',
  DURATION: 'DURR',
  BACK_TO_CALENDAR: 'Terug naar kalender',
  COMPLETE_APPOINTMENT: 'Volledige afspraak',
  EMPLOYEE: 'Medewerker',
  ITEMS_OUTFIT: 'Artikelen in outfit',
  LAST_FITTING: 'Laatste montage',
  PREVIOUS: 'Vorig',
  NEXT: 'Volgende',
  CHOOSE_SIZE: 'Kies het formaat of scan automatisch',
  DECLARE_MEASURES: 'Ik verklaar hierbij dat de metingen correct zijn',
  INSTRUCTIONS:
    'Volg de instructies op de <a href="../../files/fitting_guide.mp4" target="_blank" rel="noopener noreferrer">Instructievideo</a> en raadpleeg vervolgens de <a href= "../../files/fitting_sizes_table.pdf" target="_blank" rel="noopener noreferrer">Referentietabel</a>',
  UNIQUE_SIZE: 'UNIEKE MAAT',
  SIZE: 'MAAT: ',
  REF_FITTING: 'Ref-fitting',
  TELEPHONE: 'Telefoon',
  HOUR: 'Uur',
  SELECT: 'Selecteer',
  CHECKOUT_NOW: 'Nu afrekenen',
  CLEAR_BAG: 'Doorzichtige tas',
  YOU_WILL_LIKE: 'Je zult het leuk vinden',
  MONTHS: 'maanden',
  ADD_BAG: 'Voeg toe aan tas',
  SELECT_QUESTION_TYPE:
    'Selecteer indien gewenst het vraagtype en raadpleeg onze FAQ:',
  FITTING_LOWERCASE: 'Grootte Beoordeling',
  NOT_FITTED: 'NIET GEPAST',
  FITTED: 'GEMONTEERD',
  NA: 'NA',
  SAVE_CHANGES: 'WIJZIGINGEN OPSLAAN',
  BIRTH_DATE_OPTIONAL: 'Geboortedatum (optioneel)',
  FIRST_NAME: 'Voornaam',
  LAST_NAME: 'Achternaam',
  SORT_BY: 'Sorteren Op',
  CART_CREDIT: 'tegoed',
  CART_CREDITS: 'tegoeden',
  CART_ADD_ADDRESS: 'Voeg adres toe',
  CART_SHIPMENT_TEXT_1: 'Maximaal',
  CART_SHIPMENT_TEXT_2: 'zendingen per jaar.',
  CART_SHIPMENT_TEXT_3: 'zendingen zijn nog beschikbaar.',
  PAYMENT_RECEIVED: 'Ontvangen',
  PAYMENT_PENDING: 'In behandeling',
  DOCUMENTS: 'Documenten',
  NOTES: 'Notities',
  TRACKING: 'Volgen',
  PARTIALLY: 'Gedeeltelijk',
  COOKIES_WARNING:
    'Wij gebruiken cookies om uw gebruikerservaring op onze site te verbeteren. Als u doorgaat met browsen, gaan we ervan uit dat u akkoord gaat met onze',
  COOKIES_WARNING_PRIVACY: 'Privacy beleid',
  SINGLE_SIZE: 'ENKELE GROOTTE',
  CUSTOMER_SUPPORT_EMAIL: 'E-mail voor klantenondersteuning:',
  CUSTOMER_SUPPORT_PHONE:
    'Voor directe klantenondersteuning, gebruik het telefoonnummer dat zich in uw geografische gebied bevindt.',
  CUSTOMER_SUPPORT_EUROPE: 'Portugal & Europese Landen',
  CUSTOMER_SUPPORT_USA: 'VS',
  CUSTOMER_SUPPORT_UNITED_KINGDOM: 'Verenigd Koninkrijk',
  CUSTOMER_SUPPORT_CANADA: 'Canada',
  CUSTOMER_SUPPORT_OBS_1: 'Belkosten naar landelijk',
  CUSTOMER_SUPPORT_0BS_2: 'vast netwerk',
  NAME: 'Naam',
  SUBJECT_PROBLEM_EMAIL: 'Ik heb een probleem met mijn bestelling',
  ORDER_FOUND: ' bestelling gevonden',
  ORDERS_FOUND: ' bestellingen gevonden',
  PRODUCT: 'Product',
  CHOOSE_YOUR_SIZE: 'KIES UW MAAT',
  RECOVER_SUCCESS:
    'Er is een nieuw wachtwoord verzonden naar de e-mail van de gebruiker',
  SIGN_UP: 'Nieuw bij het portaal?',
  SIGN_UP_LINK: 'AANMELDEN',
  RETURN_TO_LOGIN_PAGE: 'Terug naar inloggen',
  ONBOARDING: 'Onboarding',
  ONBOARDING_CONTENT_TITLE: 'Welkom op het uniformportaal',
  ONBOARDING_CONTENT_SUBTITLE: 'Vul hieronder de vereiste informatie in:',
  ACCEPT: 'Aanvaarden',
  GENDER: 'Geslacht',
  DISTRICT: 'District',
  ADDRESS: 'Adres',
  DAY: 'Dag',
  MONTH: 'Maand',
  YEAR: 'Jaar',
  JANUARY: 'Januari',
  FEBRUARY: 'Februari',
  MARCH: 'Maart',
  APRIL: 'April',
  MAY: 'Kunnen',
  JUNE: 'Juni',
  JULY: 'Juli',
  AUGUST: 'Augustus',
  SEPTEMBER: 'September',
  OCTOBER: 'Oktober',
  NOVEMBER: 'November',
  DECEMBER: 'December',
  ERROR: 'Fout',
  ERROR_CURRENT_PASSWORD_REQUIRED:
    'Huidig wachtwoord is een verplicht veld',
  ERROR_PASSWORD_CONFIRMATION_DOES_NOT_MATCH:
    'Wachtwoordbevestiging komt niet overeen met het wachtwoord',
  ERROR_PASSWORD_EQUAL_PREVIOUS:
    'Het huidige wachtwoord is gelijk aan het vorige wachtwoord. Gebruik een andere',
  ERROR_DATA_SAVED_FAILED:
    'Kan de gegevens niet wijzigen. Probeer het opnieuw',
  SUCCESS: 'Succes',
  SUCCESS_DATA_SAVED_SUCCESSFULLY: 'Gegevens succesvol bijgewerkt',
  AFGHANISTAN: 'Afghanistan',
  ALAND_ISLANDS: 'Aland-eilanden',
  ALBANIA: 'Albanië',
  ALGERIA: 'Algerije',
  AMERICAN_SAMOA: 'Amerikaans Samoa',
  ANDORRA: 'Andorra',
  ANGOLA: 'Angola',
  ANGUILLA: 'Anguilla',
  ANTARCTICA: 'Antarctica',
  ANTIGUA_AND_BARBUDA: 'Antigua en Barbuda',
  ARGENTINA: 'Argentinië',
  ARMENIA: 'Armenië',
  ARUBA: 'Aruba',
  AUSTRALIA: 'Australië',
  AUSTRIA: 'Oostenrijk',
  AZERBAIJAN: 'Azerbeidzjan',
  BAHAMAS: 'Bahamas',
  BAHRAIN: 'Bahrein',
  BANGLADESH: 'Bangladesh',
  BARBADOS: 'Barbados',
  BELARUS: 'Wit-Rusland',
  BELGIUM: 'België',
  BELIZE: 'Belize',
  BENIN: 'Benin',
  BERMUDA: 'Bermuda',
  BHUTAN: 'Bhutan',
  BOLIVIA: 'Bolivia',
  BOSNIA_AND_HERZEGOVINA: 'Bosnië-Herzegovina',
  BOTSWANA: 'Botswana',
  BOUVET_ISLAND: 'Bouvet-eiland',
  BRAZIL: 'Brazilië',
  BRITISH_INDIAN_OCEAN_TERRITORY: 'Brits-Indisch oceaan gebied',
  BRITISH_VIRGIN_ISLANDS: 'Britse Maagdeneilanden',
  BRUNEI_DARUSSALAM: 'Brunei Darussalam',
  BULGARIA: 'Bulgarije',
  BURKINA_FASO: 'Burkina Faso',
  BURUNDI: 'Burundi',
  CAMBODIA: 'Cambodja',
  CAMEROON: 'Kameroen',
  CANADA: 'Canada',
  CANARY_ISLANDS: 'Canarische eilanden',
  CAPE_VERDE: 'Kaap Groen',
  CAYMAN_ISLANDS: 'Kaaiman Eilanden',
  CENTRAL_AFRICAN_REPUBLIC: 'Centraal Afrikaanse Republiek',
  CHAD: 'Tsjaad',
  CHANNEL_ISLANDS: 'Kanaal eilanden',
  CHILE: 'Chili',
  CHINA: 'China',
  CHRISTMAS_ISLAND: 'Kersteiland',
  COCOS_KEELING_ISLANDS: 'Cocoseilanden (Keeling)',
  COLOMBIA: 'Colombia',
  COMOROS: 'Comoren',
  CONGO_KINSHASA: 'Congo (Kinshasa)',
  CONGO_BRAZZAVILLE: 'Congo (Brazzaville)',
  COOK_ISLANDS: 'Cook Eilanden',
  COSTA_RICA: 'Costa Rica',
  CROATIA: 'Kroatië',
  CUBA: 'Cuba',
  CYPRUS: 'Cyprus',
  CZECHIA: 'Tsjechisch',
  DENMARK: 'Denemarken',
  DJIBOUTI: 'Djibouti',
  DOMINICA: 'Dominica',
  DOMINICAN_REPUBLIC: 'Dominicaanse Republiek',
  ECUADOR: 'Ecuador',
  EGYPT: 'Egypte',
  EL_SALVADOR: 'El Salvador',
  EQUATORIAL_GUINEA: 'Equatoriaal-Guinea',
  ERITREA: 'Eritrea',
  ESTONIA: 'Estland',
  ETHIOPIA: 'Ethiopië',
  FALKLAND_ISLANDS_MALVINAS: 'Falklandeilanden (Malvinas)',
  FAROE_ISLANDS: 'Faeröer eilanden',
  FIJI: 'Fiji',
  FINLAND: 'Finland',
  FRANCE: 'Frankrijk',
  FRENCH_GUIANA: 'Frans-Guyana',
  FRENCH_POLYNESIA: 'Frans-Polynesisch',
  FRENCH_SOUTHERN_TERRITORIES: 'Franse zuidelijke gebieden',
  GABON: 'Gabon',
  GAMBIA: 'Gambia',
  GEORGIA: 'Georgië',
  GERMANY: 'Duitsland',
  GHANA: 'Ghana',
  GIBRALTAR: 'Gibraltar',
  GREECE: 'Griekenland',
  GREENLAND: 'Groenland',
  GRENADA: 'Granaat',
  GUADELOUPE: 'Guadeloupe',
  GUAM: 'Guam',
  GUATEMALA: 'Guatemala',
  GUERNSEY: 'Guernsey',
  GUINEA: 'Guinea',
  GUINEA_BISSAU: 'Guinee-Bissau',
  GUYANA: 'Guyana',
  HAITI: 'Haïti',
  HEARD_AND_MCDONALD_ISLANDS: 'Heard- en McDonaldeilanden',
  HONDURAS: 'Honduras',
  HONG_KONG: 'Hongkong',
  HUNGARY: 'Hongarije',
  ICELAND: 'IJsland',
  INDIA: 'Indië',
  INDONESIA: 'Indonesië',
  IRAN_ISLAMIC_REPUBLIC_OF: 'Iran (Islamitische Republiek Iran)',
  IRAQ: 'Irak',
  IRELAND: 'Ierland',
  ISLE_OF_MAN: 'Eiland Man',
  ISRAEL: 'Israël',
  ITALY: 'Italië',
  IVORY_COAST: 'Costa do Marfim',
  JAMAICA: 'Jamaica',
  JAPAN: 'Japan',
  JERSEY: 'Jersey',
  JORDAN: 'Jordanië',
  KAZAKHSTAN: 'Kazachstan',
  KENYA: 'Kenia',
  KIRIBATI: 'Kiribati',
  KOREA_NORTH: 'Noord Korea',
  KOREA_SOUTH: 'Zuid-Korea',
  KOSOVO: 'Kosovo',
  KUWAIT: 'Koeweit',
  KYRGYZSTAN: 'Kirgizië',
  LAO_PDR: 'Lao Democratische Volksrepubliek',
  LATVIA: 'Letland',
  LEBANON: 'Libanon',
  LESOTHO: 'Lesotho',
  LIBERIA: 'Liberia',
  LIBYA: 'Libië',
  LIECHTENSTEIN: 'Liechtenstein',
  LITHUANIA: 'Litouwen',
  LUXEMBOURG: 'Luxemburg',
  MACAO: 'Macau',
  MADAGASCAR: 'Madagascar',
  MALAWI: 'Malawi',
  MALAYSIA: 'Maleisië',
  MALDIVES: 'Maldiven',
  MALI: 'Mali',
  MALTA: 'Malta',
  MARSHALL_ISLANDS: 'Marshall eilanden',
  MARTINIQUE: 'Martinique',
  MAURITANIA: 'Mauritanië',
  MAURITIUS: 'Mauritius',
  MAYOTTE: 'Mayotte',
  MEXICO: 'Mexico',
  MICRONESIA_FEDERATED_STATES_OF: 'Micronesië, Federale Staten van',
  MOLDOVA: 'Moldavië',
  MONACO: 'Monaco',
  MONGOLIA: 'Mongolië',
  MONTENEGRO: 'Montenegro',
  MONTSERRAT: 'Montserrat',
  MOROCCO: 'Marokko',
  MOZAMBIQUE: 'Mozambique',
  MYANMAR: 'Myanmar',
  NAMIBIA: 'Namibië',
  NAURU: 'Nauru',
  NEPAL: 'Nepal',
  NETHERLANDS: 'Nederland',
  NETHERLANDS_ANTILLES: 'Nederlandse Antillen',
  NEW_CALEDONIA: 'Nieuw-Caledonië',
  NEW_ZEALAND: 'Nieuw-Zeeland',
  NICARAGUA: 'Nicaragua',
  NIGER: 'Niger',
  NIGERIA: 'Nigeria',
  NIUE: 'Niue',
  NORFOLK_ISLAND: 'Norfolkeiland',
  NORTH_MACEDONIA: 'Noord-Macedonië',
  NORTHERN_MARIANA_ISLANDS: 'Noordelijke Mariana eilanden',
  NORWAY: 'Noorwegen',
  OMAN: 'Oman',
  PAKISTAN: 'Pakistan',
  PALAU: 'Palau',
  PALESTINIAN_TERRITORY: 'Palestijns gebied',
  PANAMA: 'Panama',
  PAPUA_NEW_GUINEA: 'Papoea-Nieuw-Guinea',
  PARAGUAY: 'Paraguay',
  PERU: 'Peru',
  PHILIPPINES: 'Filippijnen',
  PITCAIRN: 'Picarnia-eilanden',
  POLAND: 'Polen',
  PORTUGAL: 'Portugal',
  PUERTO_RICO: 'Puerto Rico',
  QATAR: 'Katar',
  REUNION_ISLAND: 'Het eiland Réunion',
  ROMANIA: 'Roemenië',
  RUSSIAN_FEDERATION: 'Russische Federatie',
  RWANDA: 'Rwanda',
  SAINT_BARTHOLOMEW: 'Sint Bartholomeus',
  SAINT_HELENA: 'Sint Helena',
  SAINT_KITTS_AND_NEVIS: 'Saint Kitts en Nevis',
  SAINT_LUCIA: 'Sint Lucia',
  SAINT_PIERRE_AND_MIQUELON: 'Saint-Pierre en Miquelon',
  SAINT_VINCENT_AND_GRENADINES: 'Saint Vincent en de Grenadines',
  SAINT_MARTIN_FRENCH_PART: 'Saint-Martin (Frans deel)',
  SAMOA: 'Samoa',
  SAN_MARINO: 'San Marino',
  SAO_TOME_AND_PRINCIPE: 'Sao Tomé en Principe',
  SAUDI_ARABIA: 'Saoedi-Arabië',
  SENEGAL: 'Senegal',
  SERBIA: 'Servië',
  SEYCHELLES: 'Seychellen',
  SIERRA_LEONE: 'Sierra Leone',
  SINGAPORE: 'Singapore',
  SLOVAKIA: 'Slowakije',
  SLOVENIA: 'Slovenië',
  SOLOMON_ISLANDS: 'Solomon eilanden',
  SOMALIA: 'Somalië',
  SOUTH_AFRICA: 'Zuid-Afrika',
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS:
    'Zuid-Georgië en de Zuidelijke Sandwicheilanden',
  SOUTH_SUDAN: 'Zuid-Soedan',
  SPAIN: 'Spanje',
  SRI_LANKA: 'Sri Lanka',
  SUDAN: 'Soedan',
  SURINAME: 'Suriname',
  SVALBARD_AND_JAN_MAYEN_ISLANDS: 'Svalbard- en Jan Mayen-eilanden',
  SWAZILAND: 'Swaziland',
  SWEDEN: 'Zweden',
  SWITZERLAND: 'Zwitserland',
  SYRIAN_ARAB_REPUBLIC_SYRIA: 'Syrische Arabische Republiek (Syrië)',
  TAIWAN_REPUBLIC_OF_CHINA: 'Taiwan, Republiek China',
  TAJIKISTAN: 'Tadzjikistan',
  TANZANIA_UNITED_REPUBLIC_OF: 'Tanzania, Verenigde Republiek',
  THAILAND: 'Thailand',
  TIMOR_LESTE: 'Oost Timor',
  TOGO: 'Togo',
  TOKELAU: 'Tokelau',
  TONGA: 'Tonga',
  TRINIDAD_AND_TOBAGO: 'Trinidad en Tobago',
  TUNISIA: 'Tunesië',
  TURKEY: 'Turkije',
  TURKMENISTAN: 'Turkmenistan',
  TURKS_AND_CAICOS_ISLANDS: 'Turks- en Caicoseilanden',
  TUVALU: 'Tuvalu',
  UGANDA: 'Oeganda',
  UKRAINE: 'Oekraïne',
  UNITED_ARAB_EMIRATES: 'Verenigde Arabische Emiraten',
  UNITED_KINGDOM: 'Groot-Brittannië',
  UNITED_STATES_OF_AMERICA: 'VS',
  URUGUAY: 'Uruguay',
  US_MINOR_OUTLYING_ISLANDS: 'Kleine afgelegen eilanden van de VS',
  UZBEKISTAN: 'Oezbekistan',
  VANUATU: 'Vanuatu',
  VATICAN_CITY: 'Vaticaanstad',
  VENEZUELA_BOLIVARIAN_REPUBLIC: 'Venezuela (Bolivariaanse Republiek)',
  VIETNAM: 'Vietnam',
  VIRGIN_ISLANDS_US: 'Maagdeneilanden, VS',
  WALLIS_AND_FUTUNA_ISLANDS: 'Wallis- en Futuna-eilanden',
  WESTERN_SAHARA: 'Westelijke Sahara',
  YEMEN: 'Jemen',
  ZAMBIA: 'Zambia',
  ZIMBABWE: 'Zimbabwe',
  SUCCESS_ADDRESS_SAVED_SUCCESSFULLY: 'Adres succesvol opgeslagen',
  ORDER_REQUEST_WAS_COMPLETED: 'Uw bestelling is succesvol afgerond',
  ORDER_REQUEST_WAS_RECEIVED: 'Uw bestelverzoek is ontvangen',
  ORDER_NUMBER: 'Bestellingsnummer',
  ORDER_COMPLETED_DETAILS_1:
    'Een e-mail met de gegevens over uw bestelling is verzonden naar het door u opgegeven e-mailadres.',
  ORDER_COMPLETED_DETAILS_2: 'Bewaar deze voor uw administratie.',
  ORDER_COMPLETED_DETAILS_3:
    'U kunt op elk moment naar de pagina Mijn Bestellingen gaan om de status van uw bestelling te controleren.',
  ORDER_RECEIVED_DETAILS_1:
    'De bestelgegevens zijn naar het opgegeven e-mailadres verzonden.',
  ORDER_RECEIVED_DETAILS_2:
    'Wacht nu vriendelijk op de goedkeuring van uw bedrijf.',
  ORDER_RECEIVED_DETAILS_3:
    'U kunt de voortgang op elk moment controleren door naar de sectie "Mijn bestellingen" te gaan.',
  CONTINUE_SHOPPING: 'Doorgaan met winkelen',
  BUTTON_MY_ORDERS: 'Mijn bestellingen',
  THANK_YOU: 'Bedankt',
  FITTING_SCHEDULE_NON_EXISTANT:
    'Helaas zijn er geen slots beschikbaar om uw aanpassessie te plannen. Neem dan contact op met je leidinggevende.',
  PROCEED_TO_MEASURES: 'Ga verder naar Maatregelen',
  LAYOUT_VIEW: 'Layoutweergave',
  IMAGE_VIEW: 'Beeldweergave',
  CHECK_TERMS_SERVICE: 'U moet verklaren dat de metingen correct zijn',
  REJECT: 'Afwijzen',
  PRIVACY_POLICY_CHECK:
    'Ik verklaar dat ik het privacybeleid van SKYPRO heb gelezen en aanvaard',
  PRIVACY_POLICY_CHECK_UNVALID:
    'U moet het privacybeleid van SKYPRO accepteren om dit portaal te kunnen gebruiken'
}
