import React, {useState, useEffect} from 'react'

import {ShoppingOutlined} from '@ant-design/icons'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {ClearShoppingBag} from '../../../infra/requests/BagRequests'
import Currency from '../../../infra/utils/Currency'
import GetImage from '../Image'
import {
  Bag,
  BagItemsSpan,
  BagList,
  BagRow,
  BagTitle,
  BottomContainer,
  CheckoutButton,
  IconSkyproClose,
  InnerBagRow,
  ListItem,
  MyBag,
  ClearBag,
  Subtotal,
  SubtotalResult,
  TopContainer,
  BagRowNoResults,
  NoItems,
  SideBar,
  SideBarContent,
  SideBarTitle,
  RecommendedList,
  Recommended,
  RecommendedLink,
  RecommendedImage,
  RecommendedName,
  RecommendedPrice
} from './BagStyles'
import CartItem from './CartItem'

const ShoppingBag = ({
  user,
  open,
  closeBag,
  sideBar,
  bag,
  setBag,
  bagTotal,
  related,
  activeLanguage
}) => {
  const [isHover, setIsHover] = useState(false)

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  const btnStyle = {
    transition: 'all 0.5s ease',
    color: isHover
      ? user.configuration?.layoutColors?.contentButtonText
      : user.configuration?.layoutColors?.contentText,
    border: `1px solid ${user.configuration?.layoutColors?.contentText}`,
    backgroundColor: isHover
      ? user.configuration?.layoutColors?.contentText
      : 'transparent'
  }

  const ClearCart = async () => {
    const {success} = await ClearShoppingBag()

    if (success) {
      setBag([])
    }
  }

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [open])

  return (
    <MyBag open={open}>
      <Bag open={open}>
        <TopContainer>
          <BagRow>
            <BagTitle>
              <ShoppingOutlined style={{marginRight: '18px'}} />
              <Translate id='MY_BAG' />
              <BagItemsSpan>
                {' '}
                ({bag.reduce((total, item) => (total += item.units), 0)})
              </BagItemsSpan>
              <IconSkyproClose onClick={closeBag} />
            </BagTitle>
            {bag.length !== 0 && (
              <>
                <InnerBagRow>
                  <BagList>
                    <ListItem>
                      <Subtotal>
                        <Translate id='SUBTOTAL' />
                      </Subtotal>
                      <SubtotalResult>
                        {Currency.format(bagTotal, user.currencyCode)}
                      </SubtotalResult>
                    </ListItem>
                  </BagList>
                </InnerBagRow>
                <InnerBagRow>
                  <CheckoutButton
                    as='a'
                    href='/checkout'
                    style={btnStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Translate id='CHECKOUT_NOW' />
                  </CheckoutButton>
                  <ClearBag
                    onClick={ClearCart}
                    style={{
                      color: user.configuration?.layoutColors?.contentText
                    }}
                  >
                    <Translate id='CLEAR_BAG' />
                  </ClearBag>
                </InnerBagRow>
              </>
            )}
            {bag.length === 0 && (
              <BagRowNoResults>
                <NoItems>
                  <Translate id='NO_ITEMS' />
                </NoItems>
              </BagRowNoResults>
            )}
          </BagRow>
        </TopContainer>
        <BottomContainer
          style={{height: 'calc(100% - 319px)', overflowY: 'auto'}}
        >
          <CartItem user={user} bag={bag} setBag={setBag} />
        </BottomContainer>
      </Bag>
      {related?.length > 0 && (
        <SideBar sideBar={sideBar} open={open}>
          <SideBarContent>
            <SideBarTitle>
              <Translate id='YOU_WILL_LIKE' />
            </SideBarTitle>
            <RecommendedList>
              {related.map((product) => (
                <Recommended key={product?.productId}>
                  {/* '/products/:positionId'  */}
                  <RecommendedLink href={`/products/${product.productId}`}>
                    <RecommendedImage
                      src={GetImage(
                        product?.product?.productImage.find(
                          (x) => x?.cover
                        )
                      )}
                      alt={
                        product?.product?.productImage.find(
                          (x) => x?.cover
                        )?.image?.filename
                      }
                    />
                    <RecommendedName>
                      {
                        product?.product?.productTranslation?.find(
                          (x) => x?.languageId === activeLanguage?.code
                        )?.name
                      }
                    </RecommendedName>
                    <RecommendedPrice>
                      {product?.product?.totalValue}€
                    </RecommendedPrice>
                  </RecommendedLink>
                </Recommended>
              ))}
            </RecommendedList>
          </SideBarContent>
        </SideBar>
      )}
    </MyBag>
  )
}

ShoppingBag.propTypes = {
  closeBag: PropTypes.func,
  open: PropTypes.bool.isRequired
}

ShoppingBag.defaultProps = {
  closeBag: () => {}
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(ShoppingBag))
