import {CloseOutlined} from '@ant-design/icons'
import {Collapse, Radio} from 'antd'
import styled from 'styled-components'

import {
  PrimaryTextColor,
  Lightgrey,
  TertiaryColor,
  Grey,
  PrimaryColor
} from '../../shared/styles/_colors'
import {device} from '../../shared/styles/_responsive'
import {
  LabelText,
  PanelTopSubTitle,
  AddressSubTitle,
  AccountSettingsSubtitle,
  AddressTextTitle,
  MediaType,
  BodyText,
  Assistant600,
  PanelTopTitle,
  Assistant300,
  IconSize,
  Assistant800,
  LblText
} from '../../shared/styles/_texts'
import {EditButton} from '../../shared/styles/BasicStyles'

const {Panel} = Collapse

export const HeadingSection = styled.section`
  padding-bottom: 40px;
  padding-top: 50px;
  margin-top: 0px;
  height: 169px;
  min-height: 100px;
  display: table;
  width: 100%;
  position: relative;
  z-index: 1;

  @media ${device.tablet} {
    padding-bottom: 20px;
    padding-top: 20px;
  }
`

export const HeadingWrapper = styled.div`
  @media ${device.tablet} {
    padding: 72px 50px 0px;
  }

  position: relative;
  max-width: 1440px;
  padding: 72px 50px 0px;
  width: 100%;
  margin: 0 auto;
`

export const Article = styled.article`
  @media ${device.tablet} {
    padding: 15px 0;
  }
  position: relative;
  padding: 15px 0;
  display: table-cell;
  vertical-align: middle;
`

export const HeadingTitle = styled.p`
  font-family: 'Campton';
  font-weight: bold;
  font-size: ${PanelTopTitle};
  line-height: 24px;
  display: block;
  margin-bottom: 10px;
  margin-top: 0;
`

export const HeadingSpan = styled.span`
  font-family: ${Assistant300};
  font-weight: normal;
  text-transform: inherit;
  font-size: ${PanelTopSubTitle};
  letter-spacing: 1px;
  display: block;
`

export const CartContainer = styled.section`
  width: 100%;
  text-align: center;
  font-size: 0;
  height: 100%;

  display: block;
  background-color: ${TertiaryColor};
`

export const CartWrapper = styled.div`
  @media ${device.tablet} {
    display: flex;
    flex-direction: column-reverse;
  }

  padding: 0;
  display: table;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`

export const CartAside = styled.aside`
  display: table-cell;
  width: 50%;
  height: 100%;
  position: relative;
  vertical-align: top;

  &:first-of-type {
    padding-top: 40px;
    width: 55%;
    max-width: 810px;
    text-align: left;
    min-width: 525px;
    padding: 70px 6%;

    @media ${device.laptop} {
      padding: 70px 4%;
      min-width: 400px;
    }
    @media ${device.tablet} {
      width: 100%;
      padding: 25px 4%;
      min-width: inherit;
      display: inline-block;
    }
  }

  &:nth-of-type(2) {
    padding-top: 65px;
    background-color: ${Lightgrey};
    padding: 90px 5.2%;
    width: 45%;

    @media ${device.tablet} {
      padding-top: 40px;
      padding-right: 3%;
      padding-bottom: 10px;
      padding-left: 3%;
      width: 100%;
      display: inline-block;
    }
    @media ${device.laptop} {
      padding: 28px 3%;
    }
  }
`

export const PaymentContainer = styled.div`
  margin: 0;
  padding: 0;
`

export const Utilities = styled.ul`
  margin-top: 18px;
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0;

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`

export const Utility = styled.li`
  font-size: ${PanelTopSubTitle};
  margin-right: 10px;
  font-family: ${Assistant600};
  letter-spacing: normal;
  display: inline-block;
  text-align: left;
  vertical-align: top;
`

export const UtilityLink = styled.a`
  color: ${PrimaryTextColor};
  display: inline-block;
`

export const InfoList = styled.ul`
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 45px;
  padding: 0;
`

export const Info = styled.li`
  height: auto;
  min-height: 80px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: inline-block;
  width: 100%;
  border-top: 1px solid hsla(0, 0%, 71%, 0.47);
  border-left: 1px solid hsla(0, 0%, 71%, 0.47);
  border-right: 1px solid hsla(0, 0%, 71%, 0.47);
  font-size: ${BodyText};
  vertical-align: middle;
  position: relative;
  font-family: ${Assistant600};
  text-align: left;
  margin-right: 2px;

  &:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
  }

  &:nth-of-type(2) {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    top: -1px;
    border-bottom: 1px solid hsla(0, 0%, 71%, 0.47);
  }

  @media ${device.tabletS} {
    height: auto;
  }
`

export const InfoText = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 20px;

  @media ${device.tabletS} {
    display: inline-block;
    align-items: initial;
    padding: 10px 20px;
  }
`

export const AddressList = styled(InfoText)`
  padding: 20px;
  border-top: 1px solid hsla(0, 0%, 71%, 0.47);
`

export const InfoTextSpan = styled.span`
  color: ${Grey};
  min-width: 65px;
  min-height: 50px;
  margin-right: 5%;
  font-family: ${Assistant300};
  font-size: ${BodyText};
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  display: inline-block;

  @media ${device.tabletS} {
    width: 100%;
  }
`

export const InfoTextContact = styled.p`
  flex-grow: 3;
  font-family: ${Assistant600};
  font-size: ${BodyText};
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  display: inline-block;
  min-height: 50px;
  margin-bottom: 0;

  @media ${device.tabletS} {
    width: 100%;
  }
`

export const ShipContainer = styled.div`
  width: 100%;
  display: inline-block;
  flex-grow: 3;
`

export const ChangeLink = styled.a`
  color: ${PrimaryTextColor};
  text-transform: uppercase;
  font-family: 'Assistant';
  font-size: ${PanelTopSubTitle};
  font-weight: bold;
  letter-spacing: 0.8px;
  display: inline-block;
  text-align: left;

  @media ${device.tabletS} {
    position: absolute;
    top: 12px;
    right: 20px;
  }
`

export const SelectLink = styled(ChangeLink)``

export const ShippingLabel = styled.label`
  max-width: 255px;
  position: relative;
  width: 100%;
  display: inline-block;
  font-size: ${LblText};
  font-family: 'Assistant';
  margin-bottom: 0.5rem;
  color: ${Grey};
`

export const ShippingAddress = styled.p`
  max-width: 255px;
  flex-grow: 3;
  font-family: 'Assistant';
  font-size: ${BodyText};
  font-weight: normal;
  line-height: 1.43;
  text-align: left;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 1rem;

  @media ${device.tabletS} {
    width: 100%;
  }
`

export const PaymentMethods = styled.div`
  padding-bottom: 46px;
  border-bottom: 1px solid rgba(76, 76, 76, 0.16);
  position: relative;
  text-align: center;
  display: inline-block;
  width: 100%;
  font-size: 0;
`

export const Fieldset = styled.fieldset`
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  &:first-of-type {
    margin-bottom: 50px;
  }
  &:last-of-type {
    margin-bottom: 40px;
  }
`

export const RowContainer = styled.div`
  margin-bottom: 0;
  margin-top: 0;
  position: relative;
  display: inline-block;
  width: 100%;
`

export const RowDiv = styled.div`
  text-align: center;
  margin-right: 0px;
  margin-left: 0px;
  position: relative;
  height: 100%;
  display: flex;
  vertical-align: middle;
  font-size: 0;
  flex-wrap: wrap;
  justify-content: center;

  @media ${device.tabletS} {
    width: 100%;
  }
`

export const ConcludeBtn = styled(EditButton)``

export const PaymentTitle = styled.legend`
  margin-bottom: 7px;
  font-family: 'Assistant';
  font-size: ${AccountSettingsSubtitle};
  font-weight: normal;
  text-align: left;
  float: left;
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  line-height: inherit;
  white-space: normal;

  @media ${device.tablet} {
    min-width: 50%;
    margin: 20px 0;
  }
`

export const PaymentInfo = styled.label`
  width: 100%;
  font-family: 'Assistant';
  font-size: ${BodyText};
  font-weight: normal;
  text-align: left;
  color: ${Grey};
  display: inline-block;
  margin-bottom: 15px;
`

export const PaymentMethodsContainer = styled.div`
  margin-bottom: 0;
  margin-top: 0;
  position: relative;
  display: inline-block;
  width: 100%;

  @media ${device.tablet} {
    width: 100%;
  }
`

export const PaymentCollapse = styled(Collapse)`
  width: 100%;
  max-width: 525px;
`

export const FirstPanel = styled.img`
  margin-right: 5px;
  max-height: 21px;

  @media ${device.mobileM} {
    max-width: 25px;
  }

  @media ${device.mobileS} {
    max-width: 20px;
  }
`

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 20px;

  @media ${device.tabletS} {
    display: inline-block;
    align-items: initial;
    padding: 10px 20px;
  }
`

export const InfoShipping = styled(Info)`
  &:first-of-type {
    border-bottom: 1px solid hsla(0, 0%, 71%, 0.47);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`

export const AsideArticle = styled.article`
  width: 100%;
  font-family: 'Assistant';
  color: ${PrimaryTextColor};
  font-size: ${AddressTextTitle};
  text-align: left;
  height: 70px;
  margin-bottom: 30px;
  position: relative;
  max-width: 430px;
  margin: 35px auto;
  display: inline-block;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    max-width: 100%;
  }
`

export const CloseButton = styled.a`
  position: absolute;
  right: 0;
  top: -15px;
  line-height: 1;
`

export const Picture = styled.figure`
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  margin-right: 20px;
  max-width: 90px;
  margin: 0 0 1rem;

  @media ${device.laptopL} {
    margin-right: 6%;
    max-width: 80px;
  }

  @media ${device.tabletS} {
    margin-right: 3%;
    max-width: 60px;
  }
`

export const PictureImg = styled.img`
  position: absolute;
  width: 95%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  vertical-align: middle;
  border-style: none;

  @media ${device.tabletS} {
    width: 100%;
  }
`

export const ProductFields = styled.div`
  max-width: 215px;
  margin-right: 18%;
  display: inline-block;
  vertical-align: top;

  @media ${device.desktop} {
    margin-right: 10%;
  }

  @media ${device.laptopL} {
    max-width: 150px;
  }
`

export const ProductPrice = styled.p`
  white-space: nowrap;
  position: absolute;
  width: auto;
  max-width: 60px;
  display: block;
  right: 0;
  top: 35%;
  transform: translateY(-50%);
  font-size: ${BodyText};
  font-family: 'Assistant';
  font-weight: normal;
  line-height: 30px;
`

export const CheckoutOperators = styled.div`
  position: absolute;
  right: 0;
  bottom: -15px;
`

export const LessOperator = styled.a`
  color: ${PrimaryTextColor};
  pointer-events: ${({$disabled}) => ($disabled ? 'none' : 'default')};
`

export const OperatorNumber = styled.span`
  display: inline;
  font-weight: normal;
  font-size: ${BodyText};
  margin: 0 10px;
  position: relative;
  line-height: 20px;
  width: 100%;
  margin-bottom: 0 !important;
`

export const PlusOperator = styled.a`
  color: ${PrimaryTextColor};
`

export const ClosingIcon = styled(CloseOutlined)`
  color: ${Grey};
  font-size: ${PanelTopSubTitle};
`

export const ProductGenre = styled.h2`
  font-weight: bold;
  font-size: ${MediaType};
  letter-spacing: 1.4px;
  text-transform: uppercase;
`

export const ProductName = styled.span`
  font-weight: bold;
  font-size: ${LabelText};
  display: inline-block;
  position: relative;
  line-height: 20px;
  width: 100%;
`

export const ProductColorSize = styled.p`
  display: inline-block;
  position: relative;
  line-height: 20px;
  width: 100%;
  font-size: ${BodyText};
`

export const RowInfo = styled.div`
  padding-top: 28px;
  padding-right: 0px;
  padding-bottom: 10px;
  padding-left: 0px;
  border-top: 1px solid hsla(0, 0%, 71%, 0.16);
`

export const Form = styled.div`
  position: relative;
  max-width: 525px;
  text-align: center;
  display: inline-block;
  width: 100%;
  font-size: 0;
`

export const BillingRow = styled(RowContainer)`
  width: 100%;
`

export const BillingTitle = styled(PaymentTitle)`
  margin-bottom: 30px;
`

export const CollapsePanel = styled(Panel)`
  font-family: 'Assistant';
  text-align: left;
  width: 100%;
  max-width: 525px;
  font-size: ${AddressSubTitle};

  .ant-collapse-header {
    font-family: ${Assistant600};
    background-color: ${TertiaryColor};
    padding: 12px 30px;
  }

  .ant-collapse-content {
    background-color: ${Lightgrey};
    color: ${Grey};
    padding: 30px 25px;
    width: 100%;
  }
`

export const RadioButton = styled(Radio)`
  .ant-radio-checked .ant-radio-inner {
    border: 4px solid ${PrimaryColor} !important;
  }

  .ant-radio-checked .ant-radio-inner:after {
    display: none;
  }
`

export const InputWrapper = styled.div`
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  display: inline-block;
  height: inherit;
  vertical-align: middle;
  text-align: left;
  position: relative;
  font-size: 0;
`

export const CodeInput = styled.input`
  padding: 5px 20px 5px;
  width: 100%;
  border: 1px solid hsla(0, 0%, 71%, 0.47);
  max-width: 100%;
  font: normal ${BodyText} 'Assistant';
  line-height: 30px;
  outline: none;
  margin: 0;
  display: block;
  border-radius: 3px;
`

export const ApplyCodeContainer = styled.div`
  max-width: 50%;
  float: left;
  margin-bottom: 0;
  width: 100%;
  position: relative;
`

export const ApplyBtn = styled(ConcludeBtn)`
  float: right;
`

export const CostsRow = styled.div`
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  display: inline-block;

  &:first-of-type {
    margin-bottom: 10px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const CostsText = styled.p`
  width: auto;
  text-transform: uppercase;
  font-size: ${AddressSubTitle};
  font-family: ${Assistant600};
  letter-spacing: 1px;
  display: inline-block;
  position: relative;
  line-height: 20px;

  &:first-of-type {
    float: left;
  }
  &:last-of-type {
    float: right;
  }
`

export const RowTotal = styled.div`
  margin-bottom: 0;
  margin-right: 0px;
  margin-left: 0px;
  width: 100%;
  display: inline-block;
`

export const TotalText = styled(CostsText)`
  font-family: ${Assistant800};

  &:last-of-type {
    font-size: ${IconSize};
  }
`
