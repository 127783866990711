import React from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import {
  AlignLeft,
  AlignRight,
  FooterContainer,
  FooterCopyright,
  FooterP,
  FooterWrapper,
  SkyproLogo
} from './SmallFooterStyles'

const SmallFooter = ({className}) => (
  <FooterWrapper className={className}>
    <FooterCopyright>
      <FooterContainer>
        <AlignLeft>
          <SkyproLogo />
        </AlignLeft>
        <AlignRight>
          <FooterP>
            <Translate id='COPYRIGHT' />
          </FooterP>
        </AlignRight>
      </FooterContainer>
    </FooterCopyright>
  </FooterWrapper>
)

SmallFooter.propTypes = {
  className: PropTypes.string
}

SmallFooter.defaultProps = {
  className: ''
}

export default SmallFooter
