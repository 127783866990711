import React from 'react'

import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'

import {
  CopyrightContainer,
  CopyrightText,
  Element,
  ElementFollow,
  ElementLi,
  ElementLink,
  ElementList,
  ElementTextLink,
  FacebookIcon,
  FooterCopyright,
  FooterWrapper,
  Icon,
  IconLink,
  IconList,
  InstagramIcon,
  LinkedinIcon,
  LogoContainer,
  SkyproImg,
  TwitterIcon
} from './FooterStyle'

const Footer = ({user}) => (
  <div>
    <FooterWrapper
      style={{
        backgroundColor: user.configuration?.layoutColors?.footerBackground
      }}
    >
      <Element>
        <ElementList>
          <ElementLi>
            <ElementLink
              target='_blank'
              rel='noopener noreferrer'
              href='https://corporate.wearskypro.com/about.html'
              style={{
                color: user.configuration?.layoutColors?.footerText
              }}
            >
              <Translate id='ABOUT' />
            </ElementLink>
          </ElementLi>
          <ElementLi>
            <ElementTextLink
              target='_blank'
              rel='noopener noreferrer'
              href='https://corporate.wearskypro.com/prohealth.html'
              style={{
                color: user.configuration?.layoutColors?.footerText
              }}
            >
              <Translate id='PROHEALTH' />
            </ElementTextLink>
          </ElementLi>
        </ElementList>
      </Element>
      <Element>
        <ElementList>
          <ElementLi>
            <ElementLink
              target='_blank'
              rel='noopener noreferrer'
              href='/support'
              style={{
                color: user.configuration?.layoutColors?.footerText
              }}
            >
              <Translate id='CUSTOMER' />
            </ElementLink>
          </ElementLi>
        </ElementList>
      </Element>
      <Element>
        <ElementList>
          <ElementLi>
            <ElementFollow
              style={{
                color: user.configuration?.layoutColors?.footerText
              }}
            >
              <Translate id='FOLLOW' />
            </ElementFollow>
          </ElementLi>
          <ElementLi>
            <ElementTextLink
              target='_blank'
              rel='noopener noreferrer'
              href='https://corporate.wearskypro.com/prohealth.html'
              style={{
                color: user.configuration?.layoutColors?.footerText
              }}
            >
              <Translate id='NEWSLETTER' />
            </ElementTextLink>
          </ElementLi>
          <ElementLi>
            <ElementTextLink
              target='_blank'
              rel='noopener noreferrer'
              href='https://blog.wearskypro.com/'
              style={{
                color: user.configuration?.layoutColors?.footerText
              }}
            >
              <Translate id='BLOG' />
            </ElementTextLink>
          </ElementLi>
          <ElementLi>
            <IconList>
              <Icon>
                <IconLink href='https://www.facebook.com/wearskypro/'>
                  <FacebookIcon
                    style={{
                      color: user.configuration?.layoutColors?.footerText
                    }}
                  />
                </IconLink>
              </Icon>
              <Icon>
                <IconLink href='https://twitter.com/wearskypro/'>
                  <TwitterIcon
                    style={{
                      color: user.configuration?.layoutColors?.footerText
                    }}
                  />
                </IconLink>
              </Icon>
              <Icon>
                <IconLink href='https://www.instagram.com/wearskypro/'>
                  <InstagramIcon
                    style={{
                      color: user.configuration?.layoutColors?.footerText
                    }}
                  />
                </IconLink>
              </Icon>
              <Icon>
                <IconLink href='https://www.linkedin.com/company/unavailable/'>
                  <LinkedinIcon
                    style={{
                      color: user.configuration?.layoutColors?.footerText
                    }}
                  />
                </IconLink>
              </Icon>
            </IconList>
          </ElementLi>
        </ElementList>
      </Element>
    </FooterWrapper>

    <FooterCopyright>
      <LogoContainer>
        <SkyproImg />
      </LogoContainer>
      <CopyrightContainer>
        <CopyrightText>
          <Translate id='COPYRIGHT' />
        </CopyrightText>
      </CopyrightContainer>
    </FooterCopyright>
  </div>
)

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps)(Footer)
