import styled from 'styled-components'

import {
  ButtonPrimaryBorderColor,
  ButtonPrimaryColor,
  ButtonPrimaryTextColor,
  ButtonTertiaryBorderColor,
  ButtonTertiaryColor,
  ButtonTertiaryTextColor,
  Grey,
  PageBackground,
  PrimaryColor,
  PrimaryTextColor
} from '../../styles/_colors'
import {Assistant} from '../../styles/_texts'

const renderButtonTypes = ({disabled, type}) => {
  if (disabled === false && (type === 'primary' || type === undefined)) {
    return `
  color: ${ButtonPrimaryTextColor};
  background-color: inherit;
  border: 1px solid ${ButtonPrimaryBorderColor};
  font-family: ${Assistant};

  &:hover{
    color: ${ButtonTertiaryTextColor};
    background-color: ${ButtonPrimaryColor};
    border: 1px solid ${ButtonPrimaryBorderColor};
    font-family: ${Assistant};
  }

  &:active{
    color: ${ButtonTertiaryTextColor};
    background-color: ${ButtonPrimaryColor};
    border: 1px solid ${ButtonTertiaryBorderColor};
    font-family: ${Assistant};
  // }
  `
  }
  if (disabled === false && type === 'secondary') {
    return `
  color: ${ButtonTertiaryTextColor};
  background-color: ${ButtonPrimaryColor};
  border: 1px solid ${ButtonTertiaryBorderColor};
  font-family: ${Assistant};

  &:hover{
    color: ${ButtonPrimaryTextColor};
    background-color: ${ButtonTertiaryColor};
    border: 1px solid ${ButtonTertiaryBorderColor};
    font-family: ${Assistant};
  }

  &:active{
    color: ${ButtonPrimaryTextColor};
    background-color: ${ButtonTertiaryColor};
    border: 1px solid ${ButtonPrimaryBorderColor};
    font-family: ${Assistant};
  // }
  `
  }
  return undefined
}

export const StyledButton = styled.button`
  width: ${({$auto}) => ($auto ? 'auto' : '100%')};
  min-width: 100px;
  border-radius: 30px;
  display: inline-block;
  ${renderButtonTypes};
  cursor: pointer;
  border: ${({disabled}) => (disabled ? `1px solid ${Grey}` : 'none')};
  transition: 1s all ease;
  height: 40px;
  background-color: ${PageBackground};

  &:hover {
    background-color: ${({disabled}) =>
      disabled ? 'none' : PrimaryColor};
    border-color: ${({disabled}) => (disabled ? Grey : PrimaryColor)};
    color: ${({primary}) => (primary ? 'black' : PrimaryColor)};
  }
`

export const BackButtonStyle = styled.a`
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  padding: 5px 0;
  color: ${PrimaryTextColor};
  opacity: 0.3;

  div {
    float: left;
  }

  svg {
    margin-top: 5px;
    margin-right: 10px;
  }

  &:hover,
  &:active {
    color: ${PrimaryTextColor};
    opacity: 0.1;
  }
`
