import {createReducer} from '@reduxjs/toolkit'

import {bagSave, bagEdit, bagDelete} from './BagActions'

const initialState = {}

const BagReducer = createReducer(initialState, {
  [bagSave]: (state, action) => action.payload,
  [bagEdit]: (state, action) => ({...action.payload, ...state}),
  [bagDelete]: () => initialState
})

export default BagReducer
