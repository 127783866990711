import styled from 'styled-components'

import {device} from '../../styles/_responsive'

export const LanguageSelec = styled.select`
  margin-left: -10px;
  width: 50px;
  border: none;
  font-family: 'Campton';
  font-size: 14px;
  text-transform: uppercase;
  appearance: none;
  text-align: center;
  background-color: transparent;

  &:focus {
    outline: none;
  }
`

export const LangCont = styled.p`
  display: flex;
  margin: 0 16px 0 0;
  top: 0px;
  @media ${device.laptopSemi} {
    display: none;
  }
`

export const LangOption = styled.option``

export const BalloonTip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
`

export const BalloonTipLeft = styled.div`
  width: 1.5px;
  height: 6px;
  text-align: center;
  transform: rotate(-45deg);
`

export const BalloonTipRight = styled.div`
  width: 1.5px;
  height: 6px;
  text-align: center;
  transform: rotate(45deg);
  margin-left: 2px;
`
