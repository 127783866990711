import {Row} from 'antd'
import styled from 'styled-components'

import {
  PrimaryTextColor,
  Lightgrey,
  ErrorColor,
  SecondaryColor,
  PrimaryColor
} from '../../../styles/_colors'
import {device} from '../../../styles/_responsive'
import {
  PanelTopSubTitle,
  BodyText,
  Assistant500,
  Assistant800,
  OverlineText,
  LabelText
} from '../../../styles/_texts'

export const ProductFormContainer = styled.form`
  position: relative;
  display: inline-block;
  text-align: center;
  width: 100%;
  font-size: 0;
  @media ${device.tabletS} {
    max-width: 100%;
  }
`

export const Content = styled.div``

export const RowContainer = styled(Row)`
  width: 100%;
  margin-bottom: 0px;
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;

  &:first-of-type {
    width: 100% !important;
    margin-bottom: 0px;
    border: none;
  }

  &:nth-of-type(2) {
    margin-bottom: 15px !important;

    @media ${device.tabletS} {
      width: 100%;
    }
  }

  &:last-of-type {
    margin-top: 50px !important;

    @media ${device.tabletS} {
      width: 100%;
    }
  }
`

export const DescriptionContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
  position: relative;
  display: inline-block;
  margin-top: 50px;
`

export const AddToBagButton = styled.button`
  width: 100%;
  margin-top: 0;
  max-width: 290px;
  font-size: ${BodyText};
  line-height: 40px;
  padding: 5px 0px 2px;
  font-family: ${Assistant500};
  display: inline-block;
  text-transform: uppercase;
  border-radius: 2em;
  min-width: 11.625rem;
  letter-spacing: 0.8px;
  text-align: center;
  max-width: 100%;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  transition: all 0.5s ease;
  color: ${(props) => props.color};
  background-color: 'transparent';
  border: ${(props) => `1px solid ${props.color}`};

  &:hover {
    transition: all 0.5s ease;

    color: ${(props) => props.hoverColor};
    border: ${(props) => `1px solid ${props.color}`};
    background-color: ${(props) => props.backgroundColor};
  }
`

export const ColorContainer = styled.div`
  width: 100%;
  border: none;
  text-align: left;
  display: inline-block;
  margin: 0;
  padding: 10px 0 8px;
  position: relative;
  font-size: 0;
  vertical-align: top;
`

export const Divider = styled.div`
  border-bottom: 1px solid #dedede;
  margin: 9px 0;
  margin-bottom: 0px;
  position: relative;
  height: 1px;
  width: 100%;
  background-color: rgba(76, 76, 76, 0.16);
  display: inline-block;
`

export const PriceContainer = styled.div`
  width: 50%;
  text-align: left;
  margin-left: -1px;
  padding: 10px 0 8px;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
`

export const ColorText = styled.p`
  color: ${PrimaryTextColor};
  text-transform: uppercase;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: 6px;
  font-size: ${BodyText};
  font-family: ${Assistant500};
  font-weight: bold;
  line-height: 1.71;
`

export const ColorSpan = styled.span`
  display: inline;
  margin-left: 8px;
  text-transform: uppercase;
  vertical-align: top;
  width: 100%;
  margin-bottom: 6px;
  font-size: ${BodyText};
  font-family: ${Assistant800};
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 1.71;
  position: relative;
`

export const PriceDiv = styled.div`
  width: 100%;
  padding: 10px 0 0;
  display: inline-block;
  position: relative;
  text-align: left;
  font-size: 0;
  vertical-align: top;
`

export const Price = styled.p`
  font-size: ${LabelText};
  color: ${PrimaryColor};
  font-family: 'Campton';
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
  vertical-align: top;
  width: 100%;
  margin: 0;
`

export const CreditsUsed = styled.div`
  padding: 10px 0 0;
  width: 50%;
  text-align: left;
  margin-bottom: 0;
  font-size: 0;
  vertical-align: top;
  display: inline-block;
  position: relative;
`

export const CreditsUsedText = styled.p`
  color: ${PrimaryTextColor};
  text-transform: uppercase;
  vertical-align: top;
  margin-bottom: 6px;
  font-family: ${Assistant500};
  font-size: ${OverlineText};
  line-height: 36px;
  text-align: right;
  width: auto;
  padding-top: 6px;
  float: right;
`

export const CreditsSpan = styled.span`
  display: inline;
  margin-left: 8px;
  text-transform: uppercase;
  vertical-align: top;
  margin-bottom: 6px;
  width: auto;
  font-family: ${Assistant800};
  font-size: ${OverlineText};
  padding-left: 8px;
  line-height: 35px;
  position: relative;
`

export const OriginalPrice = styled(Price)`
  color: ${Lightgrey};
  text-decoration: line-through;
  left: -5px;
`

export const PanelSizeSelector = styled.div`
  font-size: 12px;
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  word-spacing: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const SizeLocationSelector = styled.a`
  width: 100%;
  margin-top: 0;
  max-width: 290px;
  font-family: 'Assistant';
  font-weight: ${({active}) => (active === 1 ? 'bold' : 'normal')};
  color: ${({active}) =>
    active === 1 ? PrimaryTextColor : SecondaryColor};

  &:hover {
    font-weight: ${({active}) => (active === 1 ? 'bold' : 'normal')};
    color: ${({active}) =>
      active === 1 ? PrimaryTextColor : SecondaryColor};
  }
`

export const ShippingLink = styled.a`
  width: 100%;
  margin-top: 0;
  max-width: 290px;
  font-size: ${PanelTopSubTitle};
  text-transform: uppercase;
  display: inline-block;
  text-decoration: underline;
  margin-bottom: 25px;
  font-family: 'Assistant';
  font-weight: bold;
  color: ${PrimaryTextColor};

  &:hover {
    color: ${ErrorColor};
    text-decoration: underline;
  }

  &:active {
    color: ${ErrorColor};
    text-decoration: underline;
  }
`

export const ProductDetailsText = styled.p`
  font-family: 'Assistant';
  color: ${PrimaryTextColor};
  font-size: ${PanelTopSubTitle};
  width: 100%;
  line-height: 1.71;
  margin-bottom: 10px;
  overflow-wrap: break-word;
`
