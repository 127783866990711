import React from 'react'

import {PropTypes} from 'prop-types'
import {Translate} from 'react-localize-redux'

import Comfort from '../../../assets/prohealth/absolute-comfort.svg'
import ProHealthLogo from '../../../assets/prohealth/tech_logo.svg'
import {
  Container,
  ProductCharacteristics,
  Subtitle,
  TextHeader,
  Wrapper,
  SkyHealthImage,
  CharacteristicsList,
  Characteristic,
  CharFigure,
  CharTitle,
  CharText,
  CharImg
} from './ProHealthStyles'

const ProHealth = ({user, technologiesObject}) => (
  <Container>
    <TextHeader
      style={{
        backgroundColor:
          user?.configuration?.layoutColors?.loginBackground,
        color: user?.configuration?.layoutColors?.footerText
      }}
    >
      <Wrapper>
        <SkyHealthImage src={ProHealthLogo} alt='SkyHealth logo' />
        <Subtitle>
          <Translate id='TECH_SUBTITLE' />
        </Subtitle>
      </Wrapper>
    </TextHeader>
    <ProductCharacteristics>
      <CharacteristicsList>
        {technologiesObject?.map((technology) => (
          <Characteristic key={technology?.technologyId}>
            <CharFigure>
              <CharImg src={Comfort} alt='Absolute Comfort' />
            </CharFigure>
            <CharTitle>
              {technology?.technology?.technologyTranslation[1]?.name}
            </CharTitle>
            <CharText>
              {
                technology?.technology?.technologyTranslation[1]
                  ?.description
              }
            </CharText>
          </Characteristic>
        ))}
      </CharacteristicsList>
    </ProductCharacteristics>
  </Container>
)

ProHealth.propTypes = {
  user: PropTypes.object,
  technologiesObject: PropTypes.object
}

ProHealth.defaultProps = {
  technologiesObject: {}
}

export default ProHealth
