import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'

import {FastCreateBagItem} from '../../infra/requests/BagRequests'
import {GetAllProducts} from '../../infra/requests/ProductRequests'
import {GetUserInfo} from '../../infra/requests/UserRequests'
import BaseLoading from '../../shared/components/loading/BaseLoading'
import PageTopPanel from '../../shared/components/panels/PageTopPanel'
import PrivacyPolicy from '../../shared/components/privacy-policy/PrivacyPolicy'
import ProductCard from './components/ProductCard'
import {
  ArrowDown,
  Container,
  ItemsTag,
  NumberItemsTag,
  ScrollableIcon,
  ScrollSticker,
  SidePanel,
  VideoContainer,
  VideoSidePanel
} from './OutfitStyle'

const Outfit = ({user, dispatch}) => {
  const [products, setProducts] = useState()
  // const [relatedProducts, setRelatedProducts] = useState([])
  const [loading, setLoading] = useState(true)
  const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false)

  useEffect(() => {
    const handlePrivacyPolicy = async () => {
      try {
        const request = await GetUserInfo()

        if (request.data.privacyPolicy === false) {
          setPrivacyPolicyModal(true)
        }
      } catch (error) {
        console.log(error)
      }
    }

    handlePrivacyPolicy()
  }, [])

  useEffect(() => {
    const GetProducts = async () => {
      const result = await GetAllProducts()

      if (result.success) {
        setProducts(result.data.items)
        setLoading(false)
      }
    }

    GetProducts()

    //   const GetRelated = async () => {
    //     const {success} = await GetOtherItems()

    //     if (success) {
    //       setRelatedProducts(data)
    //       setLoading(false)
    //     }
    //   }
    //   GetRelated()
  }, [])

  const FastAddToBag = async (product) => {
    let credits = false
    if (product?.dotation >= 0) {
      credits = true
    }
    const values = {
      productId: product?.productId,
      units: 1,
      isCredits: credits,
      languageId: user.languageId
    }

    const {success} = await FastCreateBagItem(values)

    if (success) {
      window.location.reload(false)
    }
  }

  if (loading) {
    return <BaseLoading />
  }

  return (
    <div>
      <PrivacyPolicy
        dispatch={dispatch}
        setPrivacyPolicyModal={setPrivacyPolicyModal}
        onClose={() => setPrivacyPolicyModal(true)}
        open={privacyPolicyModal}
        user={user}
      />
      <PageTopPanel
        user={user}
        title={<Translate id='OUTFIT_TITLE' />}
        subtitle={<Translate id='OUTFIT_SUBTITLE' />}
        type='withbutton'
        disabled={false}
        loading={false}
        btntype='secondary'
        style={{height: '35px', width: '300px', borderRadius: '100px'}}
      />
      <Container>
        <Row gutter={[0, 0]}>
          <Col xs={24} md={12} lg={12}>
            <VideoSidePanel>
              <ScrollableIcon>
                <ScrollSticker>
                  <ArrowDown />
                  <Translate id='SCROLL_DOWN' />
                </ScrollSticker>
              </ScrollableIcon>
              <Row gutter={[0, 0]}>
                <Col xs={2} />
                <Col xs={20}>
                  <NumberItemsTag
                    style={{
                      color: user.configuration?.layoutColors?.contentText
                    }}
                  >
                    {products.length}
                  </NumberItemsTag>{' '}
                  &nbsp;
                  <ItemsTag>
                    <Translate id='ITEMS_IN_OUTFIT' />
                  </ItemsTag>
                </Col>
                <Col xs={2} />

                <Col xs={2} />
                <Col xs={20}>
                  <VideoContainer
                    src={
                      user.gender === true
                        ? user.configuration?.videoWoman
                        : user.configuration?.videoMan
                    }
                    alt='VIDEO_LOGO'
                    autoPlay
                    playsInline
                    loop
                  />
                </Col>
                <Col xs={2} />
              </Row>
            </VideoSidePanel>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <SidePanel>
              <Row gutter={[0, 0]}>
                <Col xs={1} md={0} />
                <Col xs={22} md={24}>
                  <Row gutter={[10, 0]}>
                    {products.map((item) => (
                      <ProductCard
                        product={item}
                        key={item?.productId}
                        FastAddToBag={FastAddToBag}
                      />
                    ))}
                  </Row>
                </Col>
                <Col xs={1} md={0} />
              </Row>
            </SidePanel>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

Outfit.propTypes = {
  user: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps)(Outfit)
