import React, {useState, useEffect} from 'react'

import {connect} from 'react-redux'

import {GetEmployeeBag} from '../../../infra/requests/BagRequests'
import {GetOtherItems} from '../../../infra/requests/ProductRequests'
import ShoppingBag from '../bag/Bag'
import Burger from '../Burger/Burger'
import IconContainer from '../icon-container/IconContainer'
import LanguageContainer from '../language-container/LanguageContainer'
import Menu from '../menu/Menu'
import MobileMenu from '../mobile-menu/MobileMenu'
import WarningCookies from '../warning-cookies/CookiesWarning'
import {
  HamburgerMenu,
  HeaderContainer,
  HeaderContent,
  LogoContainerLink,
  LogoImg
} from './NavBarStyle'

const Navbar = ({user}) => {
  const [bagHidden, setBag] = useState(true)
  const [bagTotal, setBagTotal] = useState(0)
  const [cookiesShow, setCookies] = useState(
    !localStorage.getItem('hideCookie')
  )
  const [collapsed, setCollapsed] = useState(true)
  const [mobileMenu, setMobileMenu] = useState(false)
  const [sideBar, setSideBar] = useState(false)
  const [employeeBag, setEmployeeBag] = useState([])
  const [related, setRelated] = useState([])

  useEffect(() => {
    const GetBag = async () => {
      const {data, success} = await GetEmployeeBag()

      if (success) {
        setEmployeeBag(data)
        if (data.length !== 0) {
          setSideBar(true)
        } else {
          setSideBar(false)
        }

        let sum = 0
        data.forEach((element) => {
          if (element.dotation >= element.creditsAvailable) {
            sum += element.unitValue
          }
        })

        setBagTotal(sum)
      }
      setEmployeeBag(data)
    }

    const GetRelated = async () => {
      const {data, success} = await GetOtherItems()

      if (success) {
        setRelated(data)
      }
    }

    GetBag()
    setTimeout(() => {
      GetRelated()
    }, 2000)
  }, [])

  const handleCookies = () => {
    localStorage.setItem('hideCookie', true)
    setCookies(false)
  }

  return (
    <>
      <HeaderContainer
        mobileMenu={mobileMenu}
        collapsed={collapsed}
        style={{
          backgroundColor: user.configuration?.layoutColors?.primary,
          borderBottom: !collapsed
            ? '4px solid transparent'
            : user.configuration?.layoutColors?.headerBorder === true
            ? `0px solid ${user.configuration?.layoutColors?.secondary}`
            : '1px solid rgba(76,76,76,.16)'
        }}
      >
        <HeaderContent>
          <HamburgerMenu onClick={() => setMobileMenu(!mobileMenu)}>
            <Burger collapsed={collapsed} setCollapsed={setCollapsed} />
          </HamburgerMenu>
          <LogoContainerLink to='/outfit'>
            {user.configuration?.logo !== '' ? (
              <img
                src={user.configuration?.logo}
                alt={user.configuration?.title}
                style={{height: '40px'}}
              />
            ) : (
              <LogoImg />
            )}
          </LogoContainerLink>
          <Menu user={user} />
          <LanguageContainer user={user} />
          <IconContainer
            configuration={user.configuration}
            setBagHidden={() => setBag(!bagHidden)}
            bagSize={employeeBag.reduce(
              (total, item) => (total += item.units),
              0
            )}
          />
          <MobileMenu
            setMobileMenu={() => setMobileMenu(!mobileMenu)}
            open={mobileMenu}
            user={user}
          />
          <ShoppingBag
            open={!bagHidden}
            closeBag={() => setBag(!bagHidden)}
            sideBar={sideBar}
            setSideBar={setSideBar}
            bag={employeeBag}
            setBag={setEmployeeBag}
            bagTotal={bagTotal}
            related={related}
          />
        </HeaderContent>
      </HeaderContainer>
      {cookiesShow && <WarningCookies setCookiesShow={handleCookies} />}
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps)(Navbar)
