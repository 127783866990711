import {initialize, addTranslationForLanguage} from 'react-localize-redux'

import {AuthTokenKey} from '../../infra/config/LocalStorageKeys'
import {GetUserInfo, GetUserConfig} from '../../infra/requests/UserRequests'
import {Languages} from '../../infra/translations/AvailableTranslations'
import {updateInfo} from '../data/info/InfoActions'
import {userSave} from '../data/user/UserActions'

export default function start() {
  return async (dispatch) => {
    try {
      const {data} = await GetUserConfig(window.location.href)

      dispatch(
        initialize({
          languages: Languages,
          options: {
            renderToStaticMarkup: false,
            defaultLanguage: data?.defaultLanguage?.languageId || 2
          }
        })
      )
      Languages.forEach((lang) => {
        dispatch(addTranslationForLanguage(lang.file, lang.code))
      })

      const authToken = localStorage.getItem(AuthTokenKey)

      if (authToken) {
        const {data, success} = await GetUserInfo()

        if (success) {
          dispatch(userSave(data))
        }
      }

      dispatch(
        updateInfo({
          ready: true
        })
      )
    } catch (error) {
      console.log(error)
    }
  }
}
