import React, {useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'

import BagImage from '../../../assets/product_card_bag.svg'
import LocaleString from '../../../infra/constants/LocaleString'
import {FastCreateBagItem} from '../../../infra/requests/BagRequests'
import GetImage from '../../../shared/components/Image'
import {
  ImageLink,
  BottomPanel,
  Category,
  CreditsProductCardPanel,
  DurationTag,
  DurationValue,
  ImageBag,
  ImageP,
  MiddlePanel,
  ProductName,
  SubTagBot,
  TagBot,
  TagTop,
  TopPanel,
  Workgroup,
  Wrapper
} from './CreditsProductCardStyle'

const CreditsProductCard = ({user, product, activeLanguage, renews}) => {
  const [isHover, setIsHover] = useState(false)

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  const btnStyle = {
    transition: 'all 0.5s ease',
    border: isHover
      ? `1px solid ${user.configuration?.layoutColors?.contentText}`
      : '1px solid transparent',
    backgroundColor: isHover
      ? user.configuration?.layoutColors?.contentText
      : 'transparent'
  }

  function FormatDate(data) {
    const date = new Date(data)
    const day = String(date.getDate()).padStart(2, '0')
    const monthName = date
      .toLocaleString(LocaleString[user.languageId - 1].value, {
        month: 'short'
      })
      .replace('.', '')
    const year = date.getFullYear()
    const newDate = `${day} ${monthName} ${year}`

    return newDate
  }

  const FastAddToBag = async () => {
    let credits = false
    if (product?.dotation >= 0) {
      credits = true
    }
    const values = {
      productId: product?.productId,
      units: 1,
      isCredits: credits,
      languageId: user.languageId
    }

    const {success} = await FastCreateBagItem(values)

    if (success) {
      window.location.reload(false)
    }
  }

  return (
    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
      <CreditsProductCardPanel>
        <TopPanel>
          <ImageBag
            src={BagImage}
            alt='BAG LOGO'
            onClick={FastAddToBag}
            style={btnStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <Category
            style={{color: user.configuration?.layoutColors?.contentText}}
          >
            {
              product?.product?.mainCategory?.categoryTranslation?.find(
                (x) => x.languageId === activeLanguage?.code
              )?.name
            }
          </Category>
          <ProductName>
            {
              product?.product?.productTranslation?.find(
                (x) => x.languageId === activeLanguage.code
              )?.name
            }
          </ProductName>
          <Workgroup>{product?.employee?.position?.name}</Workgroup>
        </TopPanel>
        <MiddlePanel>
          <ImageLink to={`/products/${product?.productId}`}>
            <ImageP
              src={GetImage(
                product?.product?.productImage?.find((x) => x.cover)
              )}
              alt={
                product?.product?.productImage?.find((x) => x.cover)?.image
                  ?.filename
              }
            />
          </ImageLink>
        </MiddlePanel>
        <BottomPanel>
          <Row gutter={[0, 0]}>
            <Col xs={12}>
              <Row gutter={[0, 0]}>
                <Wrapper>
                  <Col xs={24}>
                    <TagTop
                      style={{
                        color:
                          user.configuration?.layoutColors?.contentText
                      }}
                    >
                      <Translate id='CREDITS_LEFT' />
                    </TagTop>
                  </Col>
                  <Col xs={24}>
                    <TagBot>
                      {product?.creditsAvailable}&nbsp;
                      <SubTagBot>
                        <Translate id='OF' />
                      </SubTagBot>
                      &nbsp;
                      {product?.dotation}
                    </TagBot>
                  </Col>
                </Wrapper>
              </Row>
            </Col>
            <Col xs={12}>
              <TagTop
                style={{
                  color: user.configuration?.layoutColors?.contentText
                }}
              >
                <Translate id='RENEWS_ON' />
              </TagTop>
              <TagBot>
                {product?.renovationDate === '0001-01-01T00:00:00'
                  ? '-'
                  : FormatDate(product?.renovationDate)}
              </TagBot>
            </Col>
            <Col xs={12}>
              <DurationTag
                style={{
                  color: user.configuration?.layoutColors?.contentText
                }}
              >
                <Translate id='DURATION' />
              </DurationTag>
            </Col>
            <Col xs={12}>
              <DurationValue>
                {Math.round(product?.productDuration / 30)}{' '}
                <Translate id='MONTHS' />
              </DurationValue>
            </Col>
          </Row>
        </BottomPanel>
      </CreditsProductCardPanel>
    </Col>
  )
}

CreditsProductCard.propTypes = {
  product: PropTypes.object.isRequired
}

export default withLocalize(CreditsProductCard)
