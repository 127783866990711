import React, {useState} from 'react'

import {Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import BagImage from '../../../assets/product_card_bag.svg'
import GetImage from '../../../shared/components/Image'
import {
  CategoryTag,
  CreditsTag,
  CreditsWrapper,
  ImageBag,
  ImageLink,
  ImageP,
  ImagePanel,
  NameTag,
  PriceTag,
  ProductPanel,
  Separator,
  WorkgroupTag
} from './ProductCardStyle'

const ProductCard = ({
  user,
  translate,
  product,
  FastAddToBag,
  activeLanguage
}) => {
  const [isHover, setIsHover] = useState(false)

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  const btnStyle = {
    transition: 'all 0.5s ease',
    border: isHover
      ? `1px solid ${user.configuration?.layoutColors?.contentText}`
      : '1px solid #dedede',
    backgroundColor: isHover
      ? user.configuration?.layoutColors?.contentText
      : '#dedede'
  }

  return (
    <Col xs={12} lg={12}>
      <ProductPanel>
        <CategoryTag
          style={{color: user.configuration?.layoutColors?.contentText}}
        >
          {
            product?.product?.mainCategory?.categoryTranslation?.find(
              (x) => x.languageId === activeLanguage.code
            )?.name
          }
        </CategoryTag>
        <NameTag>
          {
            product?.product?.productTranslation?.find(
              (x) => x.languageId === activeLanguage.code
            )?.name
          }
        </NameTag>
        <WorkgroupTag>{product?.employee?.position?.name}</WorkgroupTag>
        <ImagePanel>
          <ImageBag
            src={BagImage}
            alt='BAG LOGO'
            onClick={() => FastAddToBag(product)}
            style={btnStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <ImageLink to={`/products/${product?.productId}`}>
            <ImageP
              src={GetImage(
                product?.product?.productImage.find(
                  (x) => x.cover === true
                )
              )}
              alt='IMG_LOGO'
            />
          </ImageLink>
        </ImagePanel>
        <PriceTag
          style={{color: user.configuration?.layoutColors?.contentText}}
        >
          {product?.dotation < 2
            ? `${product?.dotation} ${translate('CART_CREDIT')}`
            : `${product?.dotation} ${translate('CART_CREDITS')}`}
        </PriceTag>
        <Separator />
        <CreditsTag>
          &nbsp; &nbsp;
          <Translate id='USED_CREDITS' />{' '}
          <CreditsWrapper
            style={{color: user.configuration?.layoutColors?.contentText}}
          >
            ({product?.dotation - product?.creditsAvailable} /{' '}
            {product?.dotation})
          </CreditsWrapper>
        </CreditsTag>
      </ProductPanel>
    </Col>
  )
}

ProductCard.propTypes = {
  translate: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(ProductCard))
