import styled from 'styled-components'

import {
  PageBackground,
  PrimaryTextColor,
  SecondaryColor
} from '../../styles/_colors'
import {device} from '../../styles/_responsive'
import {
  Campton,
  PanelTopTitle,
  PanelTopSubTitle,
  Assistant,
  AddressSubTitle,
  Assistant600,
  OverlineText
} from '../../styles/_texts'

export const TopBar = styled.div`
  width: 100%;
  min-height: 170px;
  margin-top: 72px;

  @media ${device.laptopSemi} {
    min-height: 110px;
  }
`

export const MaxWidth = styled.div`
  max-width: 1440px;
`

export const TitleLabel = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  height: 98px;
  align-items: flex-end;
  font-family: ${Campton};
  font-size: ${PanelTopTitle};

  @media ${device.laptopSemi} {
    height: 70px;
  }
`
export const SubTitleLabel = styled.div`
  font-family: ${Assistant};
  font-size: ${PanelTopSubTitle};
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: flex-start;
`
export const Align = styled.div`
  height: 170px;
  display: flex;
  justify-content: right;
  align-items: center;

  @media ${device.laptopSemi} {
    height: 110px;
  }

  @media ${device.laptop} {
    justify-content: center;
    height: 70px;
  }
`

export const OrderBtn = styled.button`
  cursor: pointer;
  min-width: 100px;
  display: inline-block;
  line-height: 40px;
  border-radius: 30px;
  font-family: 'Assistant';
  text-transform: uppercase;
  padding: 0 40px;
  font-size: ${AddressSubTitle};
  letter-spacing: 0.8px;
`

export const SortBySelect = styled.select`
  background-color: ${PageBackground};
  max-width: 300px;
  width: 100%;
  color: ${PrimaryTextColor};
  border: 1px solid ${PageBackground};
  font-family: ${Assistant600};
  font-size: ${PanelTopSubTitle};
  padding: 9px 20px;
  appearance: none;
  cursor: pointer;
  outline: none;
  line-height: 26px;
  border-radius: 3px;
  text-transform: uppercase;

  &:before {
    transform: rotate(90deg);
    top: 17px;
    right: 20px;
    content: '|';
    color: ${SecondaryColor};
    font-size: ${OverlineText};
    position: absolute;
    pointer-events: none;
    z-index: 13;
  }

  &:after {
    content: '|';
    color: #b4b4b4;
    right: 21px;
    font-size: 11px;
    top: 16px;
    position: absolute;
    pointer-events: none;
    z-index: 13;
  }
`

export const SelectOption = styled.option``
